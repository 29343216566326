import { useEffect, useState } from "react";
import {
  Modal,
  Input,
  Radio,
  Button,
  Select,
  Form,
  message,
  DatePicker,
  Upload,
} from "antd";
import { FileOutlined, UploadOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const ClinicalNotePatientForm = ({ isOpen, setOpenPatientClinicalForm, edit }) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [fetchingForms, setFetchingForms] = useState(false);
  const [data, setData] = useState([]);
  const [formOptions, setFormOptions] = useState([]);
  const [disableProceed, setDisableProceed] = useState(false);
  const [uploadDisabled, setUploadDisabled] = useState(true);

  

  useEffect(() => {
    if (edit) {
      form.setFieldsValue({
        patientName: edit.patient_name,
        dateOfBirth: data.patient_dob ? dayjs(data.patient_dob) : null,
        formType: edit.form_type,
        relevantForm: edit.form_name,
      });
      setDisableProceed(false);
      setUploadDisabled(false);
    } else {
      form.resetFields();
      setDisableProceed(true);
      setUploadDisabled(true);
    }
  }, [edit, form]);

  const handleSubmit = async (values) => {
    console.log(values)
    try {
      setLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      
      if(data.length > 0){
      sessionStorage.setItem("clinical-data", JSON.stringify(data));
      
      }
      else{
        const mewData = [{
          patient_name: values.patientName,
          patient_dob:  values.dateOfBirth.format("YYYY-MM-DD"),
          patient_email:values.patientEmail,
          form_type: values.formType
      }];
      sessionStorage.setItem("clinical-data", JSON.stringify(mewData));
      
        
      }
      message.success("Patient information submitted successfully!");
      navigate("/generate-clinical-note");
      form.resetFields();
      setOpenPatientClinicalForm(!isOpen);
    } catch (error) {
      console.log(error)
      message.error("Something went wrong. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const disableFutureDates = (current) => current && current > dayjs().endOf("day");

  const fetchRelevantForms = async () => {
    try {
      setFetchingForms(true);
      const { patientName, email, dateOfBirth, formType } = form.getFieldsValue();
      if (!patientName || !dateOfBirth || !formType) {
        message.warning("Please fill out all fields before selecting a form");
        setFormOptions([]);
        setUploadDisabled(true);
        return;
      }
      const formattedDOB = dateOfBirth.format("YYYY-MM-DD");
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + "/list_forms/",
        {
          patient_name: patientName,
          patient_dob: formattedDOB,
          form_type: formType,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );

      const data = response.data;
      if (data && data.length > 0) {
        setData(
          data.map((form) => ({
            patient_name: form.patient_name,
            file_url: form.file_url,
            form_json: form.form_json,
            form_name: form.form_name,
            patient_dob: form.patient_dob,
            form_type: form.form_type,
          }))
        );

        setFormOptions(
          data.map((form) => ({
            id: form.form_name,
            value: form.form_name,
            label: form.form_name,
          }))
        );
        setDisableProceed(false);
        setUploadDisabled(false);
      } else {
        message.info("No relevant forms exist");
        setFormOptions([]);
        setUploadDisabled(true);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        message.error("You are not authorized! please login");
        return
      }
      if (error?.response?.status === 403) {
        message.error("You are not authorized! please verified your email");
        return
      }
      message.error("Error fetching forms");
    } finally {
      setFetchingForms(false);
    }
  };

  return (
    
    <Modal
      title={<span className="text-lg font-medium">Patient Information</span>}
      open={isOpen}
      onCancel={() => setOpenPatientClinicalForm(!isOpen)}
      footer={null}
      className='w-full'
      maskClosable={false}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        className="space-y-4 py-4"
        requiredMark={false}
      >
        <Form.Item
          label="Name of the Patient"
          name="patientName"
          rules={[
            { required: true, message: "Please enter patient name" },
            { min: 2, message: "Name must be at least 2 characters" },
            { max: 50, message: "Name cannot exceed 50 characters" },
            {
              pattern: /^[a-zA-Z\s]*$/,
              message: "Name can only contain letters and spaces",
            },
          ]}
        >
          <Input placeholder="Eg: James Franklin" className="w-full p-2" />
        </Form.Item>

        <Form.Item
          label="Email of the Patient"
          name="patientEmail"
          rules={[
            { required: true, message: "Please enter the patient's email" },
            { type: "email", message: "Please enter a valid email address" },
          ]}
        >
          <Input className="p-2" placeholder="Eg: abc@gmail.com" />
        </Form.Item>

      

        <Form.Item
          label="Patient Date of Birth"
          name="dateOfBirth"
          rules={[
            { required: true, message: "Please select date of birth" },
            {
              validator: (_, value) => {
                if (!value) return Promise.resolve();
                const year = value.year();
                if (year < 1900) return Promise.reject("Invalid year");
                return Promise.resolve();
              },
            },
          ]}
        >
          <DatePicker
            className="w-full p-2"
            format="YYYY-MM-DD"
            disabledDate={disableFutureDates}
            placeholder="Select date of birth"
          />
        </Form.Item>

        <Form.Item
          label="Select Patient Form"
          name="formType"
          rules={[{ required: true, message: "Please select patient form type" }]}
        >
          <Radio.Group className="flex gap-4">
            <Radio value="adult">Adult</Radio>
            <Radio value="pediatric">Pediatric</Radio>
            <Radio value="neonatal">Neonatal</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="relevantForm"
          rules={[{ required: false, message: "Please select a relevant form" }]}
        >
          <Select
            className="w-full"
            placeholder={
              <div className="flex items-center gap-2">
                <FileOutlined />
                <span>Select Relevant form</span>
              </div>
            }
            suffixIcon={<span className="text-xs">▼</span>}
            options={formOptions}
            onDropdownVisibleChange={fetchRelevantForms}
            loading={fetchingForms}
            onChange={() => setUploadDisabled(false)}
          />
        </Form.Item>

        <Form.Item label="Upload Patient Intake Form" name="pdfUpload" className="mt-4">
          <Upload
            accept=".pdf"
            className="w-full"
            disabled={uploadDisabled}
            beforeUpload={() => false}
          >
            <Button icon={<UploadOutlined />} disabled={uploadDisabled}>
              Upload PDF
            </Button>
          </Upload>
        </Form.Item>

        <Form.Item label="Upload from Electronic Health Records - EHR/EMR">
          <Upload
            accept=".pdf"
            disabled={uploadDisabled}
            beforeUpload={() => false}
          >
            <Button icon={<UploadOutlined />} disabled={uploadDisabled}>
              Upload EHR
            </Button>
          </Upload>
        </Form.Item>

        <Form.Item className="mb-0">
          <div className="flex justify-center mt-6">
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              className="primary text-white w-44"
             
            >
              Proceed Information
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ClinicalNotePatientForm;
