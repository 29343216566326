import React, { useState, useEffect } from 'react';
import { AutoComplete, Radio } from 'antd';
import axios from 'axios';

const DiagnosisCriteria = () => {
  const [patientType, setPatientType] = useState('adult'); 
  const [diseases, setDiseases] = useState([]);
  const [selectedDisease, setSelectedDisease] = useState(null);
  const [criterias, setCriterias] = useState([]);
  const [selectedCriteria, setSelectedCriteria] = useState(null);
  const [result, setResult] = useState(null);

  const token = localStorage.getItem("accessToken");

  useEffect(() => {
    const fetchDiseases = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/get-diseases/?category=${patientType}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setDiseases(response.data.diseases || []);
      } catch (error) {
        console.error("Error fetching diseases:", error);
      }
    };

    fetchDiseases();
  }, [patientType]);

  const fetchCriterias = async (diseaseName) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/get-criteria-by-disease/?category=${patientType}&disease_name=${diseaseName}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setCriterias(response.data.criterias || []);
    } catch (error) {
      console.error("Error fetching criterias:", error);
    }
  };

  const fetchResult = async (criteriaName) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/get-dcs-by-criteria/?category=${patientType}&disease_name=${selectedDisease}&criteria_name=${criteriaName}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setResult(response.data);
    } catch (error) {
      console.error("Error fetching result:", error);
    }
  };

  return (
    <div className="p-1">
      <div className="mb-2">
        <label htmlFor="patient-select" className="block font-medium mb-1">Select Patient:</label>
        <Radio.Group
          id="patient-select"
          onChange={(e) => setPatientType(e.target.value)}
          value={patientType}
          className="mb-4"
        >
          <Radio value="adult">Adult</Radio>
          <Radio value="pediatric">Pediatric</Radio>
        </Radio.Group>
      </div>

      <div className="mb-2">
        <label htmlFor="disease-select" className="block font-medium mb-1">Select Disease:</label>
        <AutoComplete
          id="disease-select"
          placeholder="Select a disease"
          options={diseases.map((disease) => ({ value: disease }))}
          onSelect={(value) => {
            setSelectedDisease(value);
            setCriterias([]);
            setSelectedCriteria("hello");
            setResult(null);
            fetchCriterias(value);
          }}
          className="w-full mb-4"
          filterOption={(inputValue, option) =>
            option?.value.toLowerCase().includes(inputValue.toLowerCase())
          }
        />
      </div>

      <div className="mb-2">
        <label htmlFor="criteria-select" className="block font-medium mb-1">Select Criteria:</label>
        <AutoComplete
          id="criteria-select"
          key={selectedDisease}
          placeholder="Select a criteria"
          options={criterias.map((criteria) => ({ value: criteria }))}
          onSelect={(value) => {
            setSelectedCriteria(value);
            setResult(null);
            fetchResult(value);
          }}
          className="w-full mb-4"
          disabled={!selectedDisease}
          filterOption={(inputValue, option) =>
            option?.value.toLowerCase().includes(inputValue.toLowerCase())
          }
        />
      </div>

      {result && (
        <div className="">
          <div>
            <h3 className="text-base font-bold">Criteria Name:</h3>
            <p className='font-medium'>{result.criteria_name}</p>
          </div>
         
          <div>
            <h3 className="text-base mt-3 font-bold">Diagnostic Criteria:</h3>
            <pre>{result.diagnostic_criteria}</pre>
          </div>
          <div>
            <h3 className="text-base font-bold">Result:</h3>
            <pre>{result.result}</pre>
          </div>
         
          <div>
            <h3 className="text-base font-bold">Instructions:</h3>
            <p>{result.instructions}</p>
          </div>
          <div>
            <h3 className="text-base font-bold">Author:</h3>
            <p>{result.author}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default DiagnosisCriteria;
