
export const formNames = [
  "AdultNewPatientQuestionnaire",
  "AllergyAsthmaImmuno",
  "AllergyQuestionnaire",
  "FeuilleSchussler",
  "Cardiology",
  "PrimaryCareMedicalHistory",
  "DermatologyMedicalPatientForm",
  "GastrointestinalPatientIntake",
  "GynecologyOncologyIntake",
  "NeurologyPatientIntakeOne",
  "NeurologyPatientIntakeTwo",
  "QuestionnaireRheumatologySymptoms",
  "ObIntakeWomenHealth",
  "OphtalmologistIntake",
  "Depression",
  "PhysicalMedicineRehabilitation",
  "PlasticSurgery",
  "Psychiatric",
  "PsychiatryAdult",
  "RehabilitationMedicineIntake",
  "Urology",
  "RheumatologyPatientHistory",
  "VisionQuestionnaire"
];
